import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import dayjs from "dayjs";

@Injectable({
    providedIn: 'root'
})
export class QueriesService {
    
    private _baseUrl = `${environment.baseUrl}`;    

    constructor(private http: HttpClient) { }

    getMonthlySavingsFromMonday(company:String, start:Date, end:Date) {
      const startStr =  dayjs(start).format('MM-DD-YYYY'); 
        const endStr = dayjs(end).format('MM-DD-YYYY'); 
        return this.http.get<any>(`${this._baseUrl}/month-report?company=${company}&start=${startStr}&end=${endStr}`)
    }

    getYearlySavingsByAssistTypeFromMonday(company: String, start: Date, end: Date) {
        const startStr = dayjs(start).format('MM-DD-YYYY');
        const endStr = dayjs(end).format('MM-DD-YYYY'); 
        return this.http.get<any>(`${this._baseUrl}/year-assist-types?company=${company}&start=${startStr}&end=${endStr}`)
    }
    getQuarterlyReports(company: string | undefined, quarter: string, start: Date, end: Date) {
        const startStr = dayjs(start).format('MM-DD-YYYY');
        const endStr = dayjs(end).format('MM-DD-YYYY'); 
        return this.http.get<any>(`${this._baseUrl}/quarterly-reports?company=${company}&quarter=${quarter}&start=${startStr}&end=${endStr}`)
    }
    getMondayCompanies() {
        return this.http.get<any>(`${this._baseUrl}/companies`)
    }
}